//
// Badge
//


// General styles

.badge {
    text-transform: $badge-text-transfom;
    padding: 0.385rem 1rem !important;
    border-radius: 50px;
    color: #fff;
    font-weight: 500 !important;

    &.badge-warning {
        color: #fff !important;
        background-color: #fb6340;
    }

    &.badge-success {
        color: #fff !important;
        background-color: #1aae6f;
    }

    &.pl-50 {
        padding-left: .15rem !important;
    }

    &.pr-50 {
        padding-right: .15rem !important;
    }

    i {
        margin-left: 0.2rem !important;
        // margin-right: 1rem !important;
    }

    &.bg-exprimary-light {
        background-color: rgba(#4f4f4f, .12) !important;
        color: #4f4f4f;
    }

    &.bg-primary-light {
        background-color: rgba(#63b1bd, .12) !important;
        color: #63b1bd;
    }

    &.bg-secondary-light {
        background-color: rgba(#f7fafc, .12) !important;
        color: #f7fafc;
    }

    &.bg-success-light {
        background-color: rgba(#2dce89, .12) !important;
        color: #1aae6f;
    }

    &.bg-info-light {
        background-color: rgba(#11cdef, .12) !important;
        color: #11cdef;
    }

    &.bg-warning-light {
        background-color: rgba(#fb6340, .12) !important;
        color: #fb6340;
    }

    &.bg-danger-light {
        background-color: rgba(#f5365c, .12) !important;
        color: #f5365c;
    }

    a {
        color: $white;
    }
}


// Size variations

.badge-md {
    padding: .65em 1em;
}

.badge-lg {
    padding: .85em 1.375em;
}


// Multiple inline badges

.badge-inline {
    margin-right: .625rem;

    +span {
        top: 2px;
        position: relative;

        >a {
            text-decoration: underline;
        }
    }
}


// Color fixes

.badge-default {
    color: $white;
}

.badge-secondary {
    background-color: theme-color("secondary");
    color: color-yiq(theme-color("secondary"));
}


// Badge spacing inside a btn with some text

.btn {
    .badge {
        &:not(:first-child) {
            margin-left: .5rem;
        }

        &:not(:last-child) {
            margin-right: .5rem;
        }
    }
}