.header-mission-list-content {
    &:focus-visible {
        outline: none !important;
    }

    .btn {
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    .select-per-page-form {
        .select__control {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: unset;
        }

        .select__indicators {
            display: none;
        }
    }

    @media screen and (max-width: 600px) {
        .filter-block {
            display: block !important;

            .d-flex {
                display: block !important;

                .dropdown {
                    width: 100%;

                    .btn {
                        width: 100% !important;

                        &.d-flex {
                            display: flex !important;
                            justify-content: space-between !important;
                        }
                    }
                }
            }

            .btn {
                width: 100% !important;
            }
        }

        .title-block {
            &.d-flex {
                display: block !important;
            }
        }
    }
}

.select__menu {
    width: auto !important;

    .select__option {
        white-space: nowrap;
    }
}

.dropdown-menu {
    max-height: 250px;
    overflow-y: scroll;
}