.news-modal {
  .news-modal-header {
    background: linear-gradient(to bottom, #fa5252, white);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .news-modal-title {
      font-size: 1.2rem;
      margin: 0;
    }

    .news-modal-close {
      background-color: transparent;
      border: none;
      font-size: 1.9rem;
      color: white;
      cursor: pointer;
    }
  }

  .news-modal-body {
    font-size: 1rem;
    color: #333;
    padding: 1.5rem;
    overflow: hidden;
    position: relative;
    .news-modal-subtitle {
      font-weight: bold;
      color: #555;
      margin-bottom: 1rem;
    }
.news-modal-description{
  font-size: 1rem;
}
    .news-modal-content {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      img,
      video {
        max-width: 100%;
        border-radius: 8px;
      }
    }

    &.expanded {
      max-height: none;
    }
  }

  .news-modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: transparent;

    .d-flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .news-modal-nav {
      background: none;
      border: none;
      font-size: 1.2rem;
      color: rgb(35, 35, 35);
      cursor: pointer;
      padding: 5px;
      width: auto;

      &:disabled {
        opacity: 0.3;
        cursor: default;
      }
    }

    .see-more-link {
      display: inline-block;
      text-align: center;
      padding: 10px 15px;
      background-color: #fa5252;
      color: #fff;
      border-radius: 5px;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      transition: background-color 0.3s ease, transform 0.3s ease;
      width: auto;

      &:hover {
        background-color: #e04141;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
}