.invitation-item {
  width: 100%;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.notification-time{
  font-size: 0.9rem;
}
.delete-notification-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 5px;
}

.delete-notification-btn:hover {
  color: #cc0000; /* Darker red on hover */
}
