.detail-profile-page {
    position: relative;

    .media-comment {
        margin-top: 0.75rem;
    }

    @media screen and (max-width: 1440px) {

        .card {

            .card-header {
                font-size: 1rem;
            }

            .text-muted {
                font-size: 0.8rem;
            }

            .fs-2 {
                font-size: 1rem;
            }
        }



        .media-comment {
            .lh-160 {
                font-size: 0.8rem !important;
            }
        }
    }
}
.hourly-rate {
    background-color: #F2F3F9;
    border-radius: 4px;
    color: #32325d;
    font-size: 14px;
    padding: 2px 5px;
    width: max-content;
    margin-top: 1rem;
}