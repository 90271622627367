.jobs-container {
    .job-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .job-breadcrumb {
        top: 0 !important;
        left: 0 !important;
        padding-left: 0 !important;

        .breadcrumb {
            margin-bottom: 0;
            padding: 0;
        }
    }

    .job-details .custom-control-input:checked~.custom-control-label::before {
        background-color: #63b1bd !important;
    }

    .published-job {
        font-size: 13px;
    }
}

.notes-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .note-item {
        margin: 2px;
        .note-content {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          color: rgb(69, 68, 68);
        }

 
    }
}

.note-content {
    color: black;
}

.application-form{
    margin-bottom: 1.5rem;
  
    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      display: block;
    }
    
  }
 .muted {
    .react-select__control {
      background-color: #f8f9fa; 
      border-color: #e9ecef; 
      cursor: not-allowed;
  
    }
  
    .react-select__placeholder {
      color: #6c757d; 
    }
  }

  .visually-hidden{
    display: none;
  }
.application-form {
    margin-bottom: 1.5rem;
  }
  
  video {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 1rem;
  }

  .uploadVideoButton{
    background-color: #63b1bd;
    border: none;
    border-radius: 0.5rem;
    color: white;
    font-weight: bold;
    width: 8rem;
    height: 2.5rem;
    &:hover{
        border: 2px solid #63b1bd;
        background-color: white;
        color: #63b1bd;
        transition: 0.3s ease-in-out;
    }
  }

.required-skills {
  display: flex;
  flex-wrap: wrap; 
  gap: 5px;
  margin-bottom: 16px; 
  height: auto;
}


.skill {
  background-color: #edebeb; 
  padding: 4px 8px; 
  border-radius: 4px; 
  font-size: 14px; 
}

.skill-text {
  margin: 0;
  white-space: nowrap; 
}

.required-skills-text {
  margin-bottom: 8px; 
}