.card-empty {
    margin: 20px;
    box-shadow: unset !important;
    // border: 1px solid #ACBDCE;
    font-size: 5rem;
}

.card-body-empty {
    display: flex;
    justify-content: center;
    padding: 15% 0;
}