.header-recruiter {
    .breadcrumb {
        .breadcrumb-item {
            color: #545470;
            font-size: 0.8rem;

            &::before {
                color: #545470;
            }

            a,
            i {
                color: #545470;
            }
        }
    }
}