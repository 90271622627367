.card-header-container {
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.text-white {
  color: #32325d;
}

.autocomplete-input {
  display: flex;
  align-items: center;
  gap: 5px;
}

.css-13cymwt-control {
  min-height: 40px;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  margin-bottom: 0px !important;
}

.selected-tags {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.requirement-input .btn {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

#requirement {
  height: 40px !important;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.add-new-requirements {
  background-color: #61b1c1;
  border: #61b1c1;
  padding: 0.5rem;
  color: white;
  font-weight: 600;
  border-radius: 0.3rem;
}

.dropzone .dz-button {
  background-color: unset;
}

.tags-selected {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}

textarea {
  width: 100%;
  height: 150px;
  resize: none;
}

.button-submit {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
}


@media screen and (max-width: 1440px) {
  .dropzone .dz-button {
    font-size: 0.8rem;
  }
}