.bg-exprimary {
  background-color: #4f4f4f !important;
  color: white;
}

.bg-exprimary:hover {
  color: white !important;
}

.bg__exprimary--splash {
  background-color: #fa5252 !important;
  color: white;
}

.btn-viewProfile:hover {
  background: #fb3b3b;
}

.CSVImporter_FileSelector {
  border-color: rgb(152, 220, 231);
  background-color: white;
  font-weight: bold;
}

.CSVImporter_ImporterFrame {
  border-color: rgb(152, 220, 231);
}

.CSVImporter_TextButton {
  background-color: #4f4f4f !important;
  color: white;
  padding: 0.5rem 1.5rem;
}

.CSVImporter_ColumnDragCard__cardHeader>b {
  background-color: #777777 !important;
  color: white;
}

.btn-exprimary {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  color: white !important;
}

.btn-exprimary:hover {
  background: #3e3e3e !important;
  color: white !important;
  border-color: #3e3e3e;
}

.bg-secondary {
  background-color: white;
  color: red;
}

/* .bg-secondary:hover {
  transform: none !important;
} */
.btn-tags {
  background-color: #172b4d;
  transition: all 0.2s ease-in-out;
}

.btn-tags:hover {
  background-color: rgb(20, 21, 24);
}

.tags-mission .btn-tags {
  font-size: 14px;
  padding: .3rem .7rem !important;
  line-height: 16px;
  border-radius: 0.25rem;
}

.tags-mission .btn-tags a::after {
  font-size: 14px;
  margin-left: 10px;
  content: "×";
}

.text-word-break-all {
  word-break: break-all;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-exprimary:disabled {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
}

.btn-skill:hover span {
  display: inline-block !important;
}

.quickLink-bg,
.bg-gradient-blue {
  background: linear-gradient(90deg,
      #63b1bd 0%,
      rgba(99, 177, 189, 0.6) 100%) !important;
}

.tags:hover {
  background-color: #3a3939;
}

.dashed {
  border: 2px dashed #f2f2f2;
}

.btn-skill {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-skill span {
  position: absolute;
  right: -4%;
  top: -32%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.bg-aviable {
  background: #2dce89;
  color: #fff;
  border: solid 1px #2dce89;
}

.bg-not-aviable {
  background: #fa5252;
  color: #fff;
  border: solid 1px #fa5252;
}

.bg-not-part-time {
  background: #F2994A;
  color: #fff;
  border: solid 1px #F2994A;
}

.btn-viewProfile {
  background-color: #fa5252;
  border-radius: 0%;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  padding: 0.5rem 0;
}

.bg-transparent {
  background: transparent ;
}

.no-animation:hover {
  background-color: #4f4f4f !important;
  transform: none !important;
}

.wrapper {
  flex-wrap: wrap;
  gap: 5px;
}

.delete-button {
  background-color: #fa5252;
  color: white;
  font-weight: bold;
}

.text-nowwrap {
  white-space: nowrap !important;
}

.text-prewrap {
  white-space: pre-wrap !important;
}

.w-22rem {
  width: 22rem !important;
}

.border-none {
  border: none !important;
}

.border-top-left-radius-none {
  border-top-left-radius: 0 !important;
}

.border-bottom-left-radius-none {
  border-bottom-left-radius: 0 !important;
}

.border-top-right-radius-none {
  border-top-right-radius: 0 !important;
}

.border-bottom-right-radius-none {
  border-bottom-right-radius: 0 !important;
}

.w-max-content {
  width: max-content !important;
}

.text-end {
  text-align: end !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.tag {
  background-color: #172b4d;
}

@media screen and (max-width: 1440px) {
  h1 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1rem;
  }

  p,
  label,
  *::placeholder {
    font-size: 0.85rem;
  }
}

.bg-white {
  background-color: #fff !important;
}

.w-250 {
  width: 250px !important;
  min-width: 250px !important;
}

#navbarDropdownMenuLink2 {
  background: unset;
  border: none;
  box-shadow: none;
  color: #fff;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background-color: #cacaca;
}

*::-webkit-scrollbar-thumb {
  background-color: #4f4f4f;
  border-radius: 50px;
}

.h-40 {
  height: 40px;
}

.grid-data-users {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1600px) {
  .grid-data-users {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width: 1230px) {
  .grid-data-users {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 768px) {
  .grid-data-users {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 0 15px !important;
  }
}

.detail-profile-page .header {
  background-color: #4F4F4F;
  background-size: cover;
  background-position: center top;
  margin-top: -5rem;
  
}

.return-overview-page-link {
  position: relative;
  color: #fff;
  padding: 10px;
  top: 5rem;
  left: -10px;
  gap: 5px;
  display: flex;
  align-items: center;
}

.return-overview-page-link:hover {
  color: #cacaca;
}

.return-overview-page-link i {
  font-size: 16px;
  cursor: pointer;
}

.return-overview-page-link i:hover {
  color: #cacaca;
}

.return-overview-page-link span {
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
}

.header h1.display-2 {
  text-transform: capitalize;
}

.header .phone-number-text {
  border: solid 1px #cacaca;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  width: max-content;
}

.header .phone-number-text:hover {
  background-color: transparent;
  color: #fff !important;
}

.header .phone-number-text i {
  font-size: 18px;
  transform: rotate(90deg);
  margin-right: .5rem;
}

@media screen and (max-width: 1440px) {
  .header h1.display-2 {
    font-size: 1.5rem;
  }

  .header .phone-number-text {
    font-size: 1rem;
  }

  .header p.text-white {
    font-size: 1rem;
    font-weight: 400;
  }

  .header p.text-white span {
    font-size: 1rem;
    font-weight: bold;
  }

  .header p.text-white .badge a {
    font-size: 0.875rem;
  }
}

.flex-1 {
  flex: 1;
}