.lead-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.4rem;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .lead-save,
  .lead-edit {
    background-color: #61b1c1;
  }
  
  .lead-save:hover,
  .lead-edit:hover {
    background-color: #4a8b9b; 
  }
  
  .lead-cancel,
  .lead-delete {
    background-color: #fa5251;
  }
  
  .lead-cancel:hover,
  .lead-delete:hover {
    background-color: #d93a3a; 
  }