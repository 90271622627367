.settings-container {
    .profile-image-upload {
        .fallback {
            label {
                border-color: hsl(0, 0%, 80%);
                width: 100%;
                max-width: unset;
                height: 100px;

                span {
                    color: hsl(0, 0%, 80%);
                }

                svg {
                    path {
                        fill: hsl(0, 0%, 80%) !important;
                    }
                }
            }
        }

        .preview-image {
            align-items: center;

            img {
                display: block;
                margin-top: 20px;
                width: 75px;
                height: 75px;
                border: 1px solid gray;
            }
        }
    }
}

.settings-container .css-1p3m7a8-multiValue {
    background: linear-gradient(90deg,
            #63b1bd 0%,
            rgba(99, 177, 189, 0.6) 100%) !important;
    color: white !important;
    text-transform: capitalize !important;
}

.settings-container .css-wsp0cs-MultiValueGeneric {
    color: white !important;
}

.settings-container .css-9jq23d {
    color: white !important;
}