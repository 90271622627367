.drag-drop-upload {
    border: 2px dashed #e0e0e0;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
    transition: all 0.3s ease;
    min-height: 120px;
    background-color: #fafafa;
    cursor: pointer;
}

.drag-drop-upload.drag-over {
    background-color: #f0f7ff;
    border-color: #61b1c1;
    box-shadow: 0 0 0 2px rgba(97, 177, 193, 0.1);
}

.upload-label {
    color: #666;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.upload-label .fa-plus {
    background-color: #61b1c1;
    border-radius: 50%;
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: all 0.3s ease;
}

.upload-label:hover .fa-plus {
    background-color: #4a8a98;
    transform: scale(1.05);
}

.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px;
}

.file-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 6px;
    transition: all 0.2s ease;
}



.file-preview {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 8px;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.file-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-right: 8px;
    font-size: 20px;
}

.file-item a {
    color: #ff4d4f;
    font-size: 0.875rem;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.file-item a:hover {
    background-color: #fff1f0;
    color: #ff7875;
}