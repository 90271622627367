.news-card {
    margin: auto;
    margin-top: 0;
    width: 10rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(235, 233, 233);
    gap:1rem;
    overflow: hidden;
    box-sizing: border-box;
}

.news-image {
    width: 5rem;
    height: 5rem;
}

.news-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.news-title {
    text-align: center;
    font-size: 10px;
    font-weight: bolder;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-description {
    text-align: center;
    font-size: 8px;
    font-weight: 600;
}

.news-categories {
    text-align: center;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-button {
    background: transparent;
    border: 2px solid #fa5252;
    color: #fa5252;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-size: 8px;
    cursor: pointer;

    &:hover {
        background-color: #fa5252;
        color: white;
    }
}