.linear-gradient-primary {
    background: rgb(250, 82, 82) !important;
    background: linear-gradient(90deg, rgba(250, 84, 84, 1) 0%, rgba(250, 84, 84, 0.3981967787114846) 100%) !important;
    color: white !important;
}

.text-message-overflow {
    max-height: 120px;
    overflow-y: auto;
    padding-right: 10px;
}