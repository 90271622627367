.auth-register-container {
  p {
    color: #666
  }

  #navbarDropdownMenuLink2 {
    background-color: #fff;
    color: #666
  }
  .selected-user {
    border: 2px solid #63b1b2
  }
  .kind-error {
    border: 1px solid red;
  }

  .register-container {
    width: 100%;
    height: 100vh;
    background-color:  #FFFAFA;
    padding-top: 0.7rem;
    padding-left: 0;
  }
}

.step {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.next-button {
  background-color: transparent;
  color: #FA5252;
  font-weight: bold;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.next-button:hover {
  background-color: #FA5252;
  color: white;
}

.user-selection-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  
  .main-user-types {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    color: #666;

    .main-type-card {
      width: 11rem;
      text-align: center;
      padding: 15px 30px;
      border-radius: 8px;
      border: #63B1C1;
      background-color: #63B1C1;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 700;
      transition: .3s ease-in-out;
      color: white;

      &:hover {
        background-color: #ddd;
      }

      &.selected-main {
        background-color: #4caf50;
        color: white;
      }
    }
  }

  .subtype-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    .user-type-card {
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      width: 30%;
      min-width: 11rem;
      text-align: center;
      background-color: #fff;
      border: 2px solid #bababa;
      transition: 0.3s ease-in-out;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 8px;
      }

      &:hover {
        border-color: #4caf50;
      }

      &.selected-user {
        border-color: #4caf50;
        background-color: #63B1C1      }
    }
  }

  @media (max-width: 768px) {
    .sub-user-types {
      flex-direction: column;
      align-items: center;
    }
  }
}

.pricing-card {
  min-width: 18rem;
  max-width: 38rem;
width: 100%;
  border-radius: 12px;
  margin: auto;
  margin-top: 0;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  padding: 1rem;
  @media (max-width: 992px) {
    margin: 2.3rem 2rem;
  }
}

.pricing-header {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.pricing-header h3 {
  font-weight: bold;
}

.pricing-badge {
  position: absolute;
  top: 0;
  right: 10px;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
}

.basic-badge {
  background-color: #4CAF50;
}

.expert-badge {
  background-color: #FA5252;
}

.pricing-subtitle {
  font-size: 1.1rem;
  color: #555;
  font-weight: 500;
}

.pricing-description {
  font-size: 0.9rem;
  color: #777;
}

.pricing-features {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
}

.pricing-features h5 {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.pricing-features ul {
  display: inline-block;
  list-style: none;
  padding-left: 0;
}

.pricing-features li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 8px 0;
  font-size: 1rem;
  font-weight: 500;
  color: #444;
}

.pricing-features li i {
  color: #4CAF50;
}

.vat-toggle-card {
  width: 12rem;
  padding: 0.5rem;
  transition: all 0.3s ease;
  margin-bottom: 0.2rem;
margin-left: 0.9rem;
color: #212529;
}

.vat-toggle-label {
  cursor: pointer;
  display: block;
  margin: 0;
}


.vat-toggle-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkmark-circle {
  font-size: 1.2rem;
  transition: transform 0.2s ease;
}

.vat-text-container {
  display: flex;
  flex-direction: column;
}

.vat-main-label {
  font-weight: 600;
  font-size: 0.9rem;
  color: #212529;
}
