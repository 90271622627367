.switch-label {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 50px;
    position: relative;
    transition: background-color 0.2s;
    cursor: pointer;
}

.switch-label .switch-button {
    cursor: pointer;
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked+.switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.switch-label:active .switch-button {
    width: 50px;
}

.switch-container {
    position: relative;
}

.switch-text {
    font-size: 0.7rem;
}

.invoice-container .custom-switch .custom-control-label::before {
    width: 3.5rem;
    height: 22px;
    pointer-events: all;
    border: solid 1px #63b1bd;
}

.invoice-container .custom-switch .custom-control-label:after {
    left: -3.4rem;
    top: 7px;
    height: 15px;
    width: 15px;
    box-shadow: -1px 2px 3px 0 rgb(34 41 47 / 20%);
    background-color: #63b1bd;
}

.invoice-container .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #63b1bd;
}

.invoice-container .custom-switch.switch-on .custom-control-label::after {
    left: -1.8rem;
    background-color: #63b1bd;
}

.invoice-container .custom-control-input:checked~.custom-control-label::before {
    background-color: #fff;
}

.invoice-container .custom-control-input:checked~.custom-control-label span {
    color: #63b1bd !important;
}

.invoice-container .custom-switch .custom-control-label span.yes-label {
    right: 48px;
    font-size: 13px;
    line-height: 25px;
    color: #fff;
    top: 3.5px;
}

.invoice-container .custom-switch .custom-control-label span.no-label {
    right: 30px;
    top: 3.5px;
    font-size: 13px;
    line-height: 25px;
    color: #63b1bd;
}

label {
    margin-bottom: 0.5rem;
}

.form-control {
    border-radius: 10px;
    width: 100%;
    font-weight: 500;
}

.btn-primary {
    width: 100%;
    max-width: 200px;
}

.start-number {
    width: 45%;
}

.label-input {
    font-size: 0.8rem;
    margin-left:5px
}

.scrollable-paragraph {
    max-height: 150px;
    padding: 10px;
    border: 1px solid #dedede;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.scrollable-paragraph p {
    font-size: 0.7rem;
    line-height: 1.4;
    color: #6c757d;
    margin: 0;
}
.add-invoice{
    background-color: #63B1BD;
    color:white;
    display: flex;
    gap:5px;
    height: 2rem;
    align-items: center;
    justify-content: space-evenly;
    border: 0;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: 500;
    font-size:13px;
    margin-left: -2px;
    
    .fa-plus {
        background-color: #fff;
        border-radius: 50%;
        color: #63B1BD;
        width: 20px;
        height: 20px;
        justify-content: center;
        font-size: 12px;
        padding: 5px;
    }
}
.generate-invoice-enabled{
    background-color: #FA5252;
    color:white;
    display: flex;
    gap:5px;
    align-items: center;
    justify-content: space-evenly;
    border: 0;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}
.generate-invoice-disabled{
    background-color: rgb(148, 148, 150);
    color:white;
    display: flex;
    gap:5px;
    align-items: center;
    justify-content: space-evenly;
    border: 0;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}

.generate-invoice:hover .required {
    display: block;
}

.generate-invoice-disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.add-invoice i {
    background-color: white;
    color: rgb(31, 134, 244);
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;  
}
.reset-invoice  {
    max-width: 10rem;
    background-color: rgb(255, 255, 255);
    color:rgb(128, 129, 130);
    align-items: center;
    border-color : rgb(146, 146, 146);
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}
.invoice-summary{
    width: 90%;
    margin: 1rem;
}
.invoice-row{
    width: 100%;
    margin-top: 0.5rem;
}

.optionCard {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding: 10px;
    border-radius: 10px; 
    margin-bottom: 8px; 
    background-color: #e9e9e9;
    height: 3.6rem;
    font-size: 1px;
  }
  
  .optionCard p {
    font-size: 1rem;
    font-weight: 600; 
  }
  .toggle-switch {
    position: relative;
    width: 40px;
    height: 20px;
    display: inline-block;
  }
  
  .toggle-switch-checkbox {
    display: none;
  }
  
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0;
    border-radius: 20px;
    height: 20px;
    background-color: #d1d1d1;
    transition: background-color 0.3s ease-in-out;
  }
  
  .toggle-switch-label .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in-out;
    background: #63B1BD;
  }
  
  .toggle-switch-label .toggle-switch-inner:before {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    background-color: #63B1BD;
    content: '';
  }
  
  .toggle-switch-label .toggle-switch-switch {
    display: block;
    width: 14px;
    margin: 3px;
    background-color: #63B1BD;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 21px;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label {
    background-color: #63B1BD;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 3px;
    background-color: #dcdcdc;
  }
    .file-upload-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem; 
    }
    
    .file-upload-container input[type="file"] {
        position: absolute;
        left: -9999px;
    }
    
    .custom-file-upload {
        display: inline-block;
        font-size: 14px;
        color: #fff;
        background-color: #63B1BD !important;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        width: 100%;
    }
    
    .file-preview {
        display: flex;
        justify-content: center;
        position: relative;
        width: 3rem !important;
        height: 3.2rem !important;
        border-radius: 5px;
        overflow: hidden; 
        margin: 0; 
        padding: 0; 
    }
    
    .file-preview img {
        height: 2.62rem;
        width: 100%;
        object-fit: cover;
        border-radius: 5px; 
        margin: 0;
        padding: 0;
    }

    
    .delete-img-button {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        border: none;
        border-radius: 50%;
        width: 20px;
        height: 19px;
        font-size: 12px;
        cursor: pointer;
        display: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #FA5252;
    }
    
    .file-preview:hover .delete-img-button {
        display: flex;
    }
    

.disabled{
    opacity: 0.7; 
    pointer-events: none; 
    color: gray; 
}
.save-defaults {
    background-color: #63B1BD;
    color: white;
    border: none;
    padding: 9px 10px;
    border-radius: 5px;
    cursor: pointer;
    height: 2.35rem;
    margin-top: 1px;
  }
  
@media screen and (min-width: 1445px) {
    .invoice-container .custom-switch .custom-control-label span {
        top: 1.5px !important;
    }

    .invoice-container .show-unread-filter .custom-switch .custom-control-label span {
        top: 1px !important;
    }
}