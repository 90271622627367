.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.kanban-board {
  display: flex;
  overflow-x: auto;
  width: 70rem;
}

.kanban-container {
  width: 100%;
  overflow-x: scroll;
  margin-left: 1rem;
  justify-content: center;
}

.sales-pipeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-right: 1rem;
}

.sales-body {
  display: flex;
  flex-direction: column;
}


.container {
  margin-left: 20px;
  margin-right: 20px
}

.column {
  width: 30rem;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 2px;
  background-color: white;
}

.column-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;

}

.highlight {
  background-color: #e0e0e0;
}

.task-card {
  padding: 5px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.details {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.details-property {
  font-size: 11px;
  font-weight: 500;
  color: #1a1a1a;
  cursor: default;
}
.dragging {
  opacity: 0.5;
}
.viewButtons {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 20px;
}

.viewButtons Button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}

.kanban-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.status {
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
}

.profile-card {
  background-color: rgb(238, 154, 69);
  border-radius: 10px;
  text-align: center;
  color: white;
}

.profile-card p {
  font-weight: bold;
  font-size: 12px;
}

.functionTitle {
  font-weight: 600;
}

.modal {
  width: 100%
}

.actions-button {
  background-color: #fa5252;
}

.actions-button:hover {
  background-color: #f77676;
}

.list-view-container {
  width: 100%;
  margin-left: 1rem;
  overflow-x: scroll;

}

.list-view {
  width: 70rem;
  overflow-x: auto;
  margin-left: 1rem;
}

.table-row {
  cursor: pointer; 
}
.table-row:hover {
  background-color: #EEE; 
  transition: background-color 0.3s ease; 
}
@media (min-width: 1300px) {
  .content {
    width: 100%;
    justify-content: space-between;
  }

  .kanban-container {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
  }

  .kanban-board {
    width: 100%;
    justify-content: center;
  }

  .header {
    justify-content: space-between;
  }

  .list-view-container {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    width: 100%;
  }

  .list-view {
    width: 70rem;
    overflow-x: auto;
    margin-left: 1rem;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .header {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
  }
  .sales-pipeline-header {
    display: flex;
    flex-direction: column;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}