// Variables
$primary-color: #FA5252;
$secondary-color: #61b1c1;
$success-color: #28a745;
$danger-color: #dc3545;
$light-gray: #f8f9fa;
$border-color: #dee2e6;
$text-color: #212529;
$white: #fff;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.invoice-header {
  @include flex-center;
  flex-direction: column;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;

  p{
    font-size: 1rem;
    font-weight: 400;
    color: $text-color;
    margin-bottom: 0.4rem;
    text-align: center;
    width: 60%;
  }

  h1 {
      font-size: 1.6rem;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 0.4rem;
  }

  .total-amount {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
          display: block;
          color: $text-color;

          &.amount {
              font-size: 1.6rem;
              font-weight: 600;
              color: $primary-color;
          }
      }
  }
}
.late-invoice-container {
    width: 100%;
    padding: 20px;
    .table {
      width: 100%;
      margin-top: 20px;
    }
  }