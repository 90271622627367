.container-home {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
  margin: 0;
}

.onboard-card {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #333;
}

.button {
  font-size: 1.1rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  height: 3rem;
  color: #fa5252;
  display: block;
  margin: 0 auto;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 0 0 10px 10px;
}

.button:hover {
  background-color: #fa5252;
  color: white;
}

.status-card {
  height: 80%;
  min-height: 200px;
  border-radius: 10px;
  background: white;

  .status-card-body {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .status-card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 0.5rem;
  }

  .status-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .status-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
    gap:0.3rem;

    .status-label {
      color: #4a5568;
      font-size: 0.95rem;
    }

    .status-value {
      font-weight: 600;
      color: #2d3748;
      font-size: 0.97rem;
    }
  }

  .status-action-link {
    display: inline-block;
    color: #e53e3e;
    text-decoration: none;
    font-weight: 500;
    margin-top: auto;
    transition: color 0.2s ease;

    &:hover {
      color: #c53030;
    }
  }

  .limit-warning {
    font-size: 0.75rem;
    color: #e53e3e;
    margin-left: 0.5rem;
  }

  // Specific styling for GetLaunchList widget
  .getlaunchlist-widget {
    height: auto;
  }
}

.matching-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #ffffff;

  .matching-job-details {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.2rem;

    .matching-job-details-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.9rem;
      color: #6c757d;

      .matching-details-text {
        margin: 0;
      }
    }
  }

  .matching-job-status {
    display: flex;
    gap: 0.7rem;
  }

  .matching-job-title h3 {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .matching-required-skills {
    .matching-required-skills-text {
      font-weight: bold;
    }

    .matching-skills-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem;
      margin-bottom: 0.4rem;

      .matching-skill {
        background-color: #f0f0f0;
        padding: 0.25rem 0.4rem;
        border-radius: 4px;
        font-size: 0.7rem;

        .matching-skill-text {
          margin: 0;
          white-space: nowrap;
        }
      }
    }
  }

  .matching-no-skills {
    color: #6c757d;
  }

  .matching-action-buttons {
    display: flex;
    gap: 0.4rem;
    margin-top: auto;
    text-align: center;

    .btn {
      border-radius: 4px;
      height: 100%;
    }
  }
}


.extra-card {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: row;
  height: 8rem;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(29, 28, 28);

}

.extra-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.extra-text p {
  font-size: 1.15rem;
  font-weight: 300;
  text-align: left;
  line-height: 1.7rem;
}

.extra-image {
  margin-bottom: 15px;
}

.extra-image img {
  width: 60px;
  height: 60px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
}

.add-shortcut {
  background-color: #63b1bd;
  color: white;
  display: flex;
  gap: 5px;
  height: 2rem;
  align-items: center;
  justify-content: space-evenly;
  border: 0;
  border-radius: 10px;
  padding: 5px;
  font-weight: 500;
  font-size: 13px;
  margin-left: -2px;

  .fa-plus {
    background-color: white;
    border-radius: 50%;
    color: #63b1bd;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
  }
}

.subscription-name {
  font-size: 17px;
  font-weight: 600;
}

@media (max-width: 768px) {

  .onboard-card,
  .status-card,
  .matching-card,
  .extra-card {
    width: 100%;
    max-width: none;
    height: auto;
  }

  .extra-text p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .extra-image img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .container-home {
    padding: 0.5rem;
  }

  .extra-text p {
    font-size: 0.8rem;
  }

  .extra-image img {
    width: 35px;
    height: 35px;
  }
}

.steps-container {
  width: 100%;
}

.news-modal {
  .modal-content {
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }
}