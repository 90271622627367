.tag-label {
    background-color: #172b4d;
    color: #fff;
    width: fit-content;
    padding: 3px 5px;
    font-size: 12px;
    border-radius: 4px;
    text-transform: capitalize;
}

.nothing-to-show {
    padding: 10px;
    text-align: center;
}

.nothing-to-show p {
    font-size: 13px;
}

.table td,
.table th {
    vertical-align: middle;
}
