.modal-payment {
  min-width: 950px;
  padding: 2rem;
  position: relative;
}

.payment-modal-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.checklist-validation {
  font-size: 12px;
  font-weight: 500;
}

.terms-condition-payment {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid black;
  padding: 1rem;
}

.terms-condition-payment label {
  font-weight: 500;
  color: black;
}

.payment-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: auto;
}

.payment-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3d3d3d;
  width: 100%;
  border-radius: 0.5rem;
}

.payment-amount * {
  color: white;
}

.amount-details {
  display: flex;
  gap: 1rem;
}

.payment-details button {
  width: 100%;
  background-color: #fa5252;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  border: unset;
  border-radius: 0.2rem;
  height: 3rem;
}

.closable-modal {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.contractors-container img.rounded {
  border-radius: 50% !important;
  object-fit: cover;
}