.rdtPicker {
    top: unset !important;
    bottom: 0;
    margin-left: 150px;
  }
  /* Hide the default file input */
.file-upload-container input[type="file"] {
  position: absolute;
  left: -9999px; /* Moves the input off-screen */
}

/* Custom file upload button */
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

/* Style the file preview section */
.file-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
}

.file-preview-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}


