.user-card {
    display: block;

    .max-content-text {
        padding: 0;
        margin-bottom: 0.7rem !important;
        max-height: 4.9rem;
        line-height: 1.45; 
        height: calc(1.4em * 3);
    }

    .availability-text {
        position: absolute;
        top: 5%;
        left: 0%;
        width: 70%;

        .card-text {
            border-radius: 4px;
            color: white;
            font-size: 19px;
            padding: 2px 5px;
            text-align: center;

            @media screen and (max-width: 1440px) {
                font-size: 16px;
            }

            @media screen and (max-width: 425px) {
                font-size: 14px;
            }
        }
    }

    .pill-hourly-daily-rate {
        background-color: #F2F3F9;
        position: absolute;
        top: 95%;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 4px;
        color: #32325d;
        font-size: 14px;
        padding: 2px 5px;
        text-align: center;
        width: max-content;
    }

    .max-content-skills {
        max-height: 4.5rem;

        .tag-skill {
            background: rgba(156, 181, 185, 0.6) !important;
            color: black !important;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            height: max-content;
        }
    }
}

