.upgarde-plan-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
}

.text-section{
    .title{
        font-size: 1.8rem;
    }
}

.image-section img {
    max-width: 100%;
    height: auto;
}

.plan-card {
    border: 1px solid black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    border-radius: 10px;
}
