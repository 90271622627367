#navbarbg {
    padding: 0.5rem 1rem !important;

    #tabs-text {
        .inner-nav {
            @media screen and (max-width: 1440px) {
                font-size: 0.88rem;
            }
        }
    }
}