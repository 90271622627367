// Variables
$primary-color: #FA5252;
$secondary-color: #61b1c1;
$success-color: #28a745;
$danger-color: #dc3545;
$light-gray: #f8f9fa;
$border-color: #dee2e6;
$text-color: #212529;
$white: #fff;

// Mixins
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin input-style {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 0.7;
    color: $text-color;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 0.5rem;
    transition: all 0.15s ease-in-out;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba($border-color, 0.25);
    }

    &::placeholder {
        color: $text-color;
    }

    &.error {
        border-color: $danger-color;
        background-color: rgba($danger-color, 0.05);

        &:focus {
            border-color: $danger-color;
            box-shadow: 0 0 0 0.2rem rgba($danger-color, 0.25);
        }
    }
}

.late-invoice-container {
    max-width: 40rem;
    width: 100%;
    height: 100vh;
    margin: auto;
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $white;
    border-radius: 0.5rem;


    .invoice-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:stretch;
    height: 100%;
        .form-section {
            margin-bottom: 2rem;

            h2 {
                font-size: 1.25rem;
                margin-bottom: 1rem;
                color: $text-color;
            }

            .file-upload {
                p {
                    margin-bottom: 1rem;
                    color: $text-color;
                }

                .upload-box {
                    position: relative;
                    border: 2px dashed $border-color;
                    border-radius: 0.5rem;
                    padding: 2rem;
                    text-align: center;
                    cursor: pointer;
                    transition: border-color 0.3s ease;

                    &:hover {
                        border-color: $secondary-color;
                    }

                    .file-input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        cursor: pointer;
                    }

                    label {
                        color: $text-color;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }

            .form-fields {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                gap: 1.3rem;

                .form-field {
                    position: relative;

                    input,
                    select {
                        @include input-style;
                    }

                    input[type="date"] {
                        position: relative;
                        color: $text-color;

                        &::-webkit-calendar-picker-indicator {
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &::-webkit-datetime-edit {
                            padding: 0;
                        }

                        &::-webkit-datetime-edit-fields-wrapper {
                            padding: 0;
                        }

                        &::-webkit-datetime-edit-text {
                            color: transparent;
                        }

                        &::-webkit-datetime-edit-month-field,
                        &::-webkit-datetime-edit-day-field,
                        &::-webkit-datetime-edit-year-field {
                            color: $text-color;
                            padding: 0;
                        }

                        &:not(:focus):not(:valid) {
                            color: transparent;

                            &::before {
                                content: attr(data-placeholder);
                                position: absolute;
                                color: $text-color;
                                opacity: 0.7;
                            }
                        }
                    }

                    .error-message {
                        color: $danger-color;
                        font-size: 0.875rem;
                        margin-top: 0.25rem;
                        left: 0;
                    }
                }
            }
        }

        .form-actions {
            @include flex-between;
            margin-bottom: 1rem;

            button {
                padding: 0.75rem 1.5rem;
                border-radius: 0.25rem;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.3s ease;

                &.reset-btn {
                    background-color: $white;
                    border: 1px solid $border-color;
                    color: $secondary-color;

                    &:hover {
                        background-color: $light-gray;
                    }
                }

                &.submit-btn {
                    background-color: $primary-color;
                    border: none;
                    color: $white;

                    &:hover {
                        background-color: darken($primary-color, 10%);
                    }
                }
            }
        }

        .share-section {
            @include flex-center;

            .share-btn {
                background: none;
                border: none;
                color: $primary-color;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                font-size: 1rem;
                padding: 0.5rem 1rem;
                transition: all 0.3s ease;

                .share-icon {
                    font-size: 1.2rem;
                }

                &:hover {
                    color: darken($primary-color, 10%);
                }
            }
        }

        .late-invoices-list {
            .invoice-item {
                background-color: $light-gray;
                border-radius: 0.5rem;
                padding: 1rem;
                margin-bottom: 2rem;

                .invoice-item-header {
                    @include flex-between;
                    margin-bottom: 1rem;

                    .user-info {
                        .name {
                            display: block;
                            font-weight: 500;
                            margin-bottom: 0.25rem;
                        }

                        .days-late {
                            color: $danger-color;
                            font-size: 0.875rem;
                        }
                    }

                    .country {
                        color: $text-color;
                        font-size: 0.875rem;
                        font-weight: 500;
                    }
                }

                .invoice-amount {
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: $text-color;
                }
            }
        }
    }

    .file-upload-section {
        margin: 20px 0;

        p {
            color: #666;
            font-weight: 500;
        }

        .file-upload-container {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;

            .upload-button {
                width: 100%;
                padding: 12px 15px;
                background-color: white;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                gap: 0.3rem;
                align-items: center;
                cursor: pointer;
                color: #666;
                font-size: 14px;
                transition: all 0.3s ease;

                &:hover {
                    border-color: #999;
                }

                .upload-icon {
                    font-size: 18px;
                    color: #666;
                }
            }

            .delete-file-btn {
                background: none;
                border: none;
                color: #666;
                font-size: 20px;
                cursor: pointer;
                padding: 0 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: #ff4444;
                }
            }
        }
    }
}

// Responsive styles
@media (max-width: 768px) {
    .late-invoice-container {
        margin: 1rem;
        padding: 1rem;

        .invoice-header {
            text-align: center;

            h1 {
                font-size: 1.75rem;
            }

            .total-amount {
                span.amount {
                    font-size: 1.75rem;
                }
            }
        }

        .form-section {
            .form-fields {
                grid-template-columns: 1fr;
            }
        }

        .form-actions {
            flex-direction: column;
            gap: 1rem;

            button {
                width: 100%;
            }
        }
    }
}