.counter {
    min-width: 45px;
    font-size: 10px;
    color: #bbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 16px;
    text-align: center;
}

.counter > .metric {
    font-size: 20px;
    line-height: 16px;
    color: #888;
}
