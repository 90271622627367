.breadcrumb {
    background-color: transparent;

    .breadcrumb-item {
        color: #fff;

        a {
            color: #fff;
        }

        i {
            color: #fff;
        }

        +.breadcrumb-item::before {
            content: ">";
            color: #fff;
        }
    }
}