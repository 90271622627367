body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zindex {
  z-index: 99;
}

.css-1nmdiq5-menu {
  z-index: 2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-dash {
  height: 90px;
}

.card-dash.card-body {
  padding: 3rem 1.5rem !important;
  font-size: 20px;
  font-weight: 700;
  background-color: #fdf60c;
}

.text-blue {
  color: #006FEE;
}

.card-stats.card {
  min-height: 90px;
  /* max-height: 140px; */
}

.responsive-min-height {
  min-height: 400px;
}

.c-pointer {
  cursor: pointer;
}

.chat-card {
  position: relative;
}

.icon-chat-dashboard {
  position: absolute;
  top: 0;
  right: 0%;
  font-size: 45px !important;
  opacity: 0.5;
  color: white;
}

.invalid-feedback {
  display: contents !important;
}

.auth-container {
  background-color: #fff;
  min-height: 100vh;
}

.navbar-item {
  padding: 0 1rem;
  padding-top: 0;
  display: inline-flex;
  width: max-content;
}

.h-max-content {
  height: max-content !important;
}

.focus-card-size {
  height: 6rem;
}

.custom-calendar .fc-scroller {
  overflow: hidden !important;
}

/* Phone Input override */
.PhoneInputInput {
  border: none !important;
}

/* React avatar override */

.custom-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  font-family: Helvetica, Arial, sans-serif;
  transform: translate(-50%, -50%) scale(1);
  position: absolute;
  left: 50%;
  transition: all 0.5s;
  /*position: relative;*/
}

.bg-purple {
  background: rgb(126, 55, 148);
}

.custom-avatar-inner {
  width: 100px;
  height: 100px;
  line-height: initial;
  text-align: center;
  color: rgb(255, 255, 255);

  max-width: 140px;
  border-radius: 0.375rem;
  border: 3px solid #fff !important;

  transition: all 0.5s;
}

.custom-avatar-inner-2 {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  font-size: 33.3333px;
}

.custom-avatar-inner-circle {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}

.custom-avatar-inner-circle-span {
  transition: all 0.5s;
}

.custom-avatar-inner-circle-span {}

.swiper-button-next {
  height: 0.8rem !important;
  width: 0.8rem !important;
}

.swiper-button-prev {
  height: 0.8rem !important;
  width: 0.8rem !important;
  margin-left: -0.3rem;
}

.swiper-button-next:after {
  font-size: 1.5rem !important;
  color: black;
  content: '' !important;
}

.swiper-button-prev:after {
  font-size: 1.5rem !important;
  color: black;
  content: '' !important;
}

.home-link {
  grid-template-columns: repeat(auto-fill,
      minmax(min(300px, 100%), 1fr)) !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-neutral {
  color: #63b1bd !important;
}

.btn-neutral:hover {
  transform: none !important;
}

.portfolio-container {
  display: flex;
  flex-direction: column;
}

.skills-container {
  align-self: center;
  flex-wrap: wrap;
  width: 50%;
  line-height: 50px;
}

.skill-button-color {
  background-color: #172b4d !important;
  color: white !important;
}

.rounded-img {
  border-radius: 50%;
  width: 205px;
  height: 205px;
  object-fit: cover;
}

.rounded-img-profile {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  z-index: 3;
}

.rounded-img-container {
  display: flex;
  justify-content: center;
}

/*@media (max-width: 760px) { 
  .rounded-img-profile{
    width: 110px;
    height: 110px;
  }
  .rounded-img-container {
    margin-top: -15rem;
    margin-left: 10rem;
    width: 50%;
  }

}

@media (max-width: 480px) { 
  .rounded-img-profile{
    width: 110px;
    height: 110px;
  }
  .rounded-img-container {
    margin-top: -15rem;
    margin-left: 10rem;
    width: 50%;
  }

}*/


.header-from-mission .rounded-img-container {
  display: flex;
  justify-content: center;
}

.user-name {
  font-weight: 400;
  font-size: 36px;
  color: #d8d8d8;
}

.line-height-30 {
  line-height: 30px !important;
}

.primary-icon {
  color: #006FEE !important;
}

.opacity-text {
  opacity: 0.55;
}

.btn-connect {
  border: unset !important;
  margin-top: 2rem;
  color: white !important;
  font-weight: 400;
  background-color: #2dce89 !important;
}

.about-me {
  margin-top: 2rem;
  width: 715px;
  line-height: 27px;
  color: #d8d8d8;
  text-align: center;
}

.visit-btns {
  margin-top: 2rem;
  width: 348px;
  color: #fa5252 !important;
  border-radius: 4px;
}

.recent-project-title {
  text-align: center;
  align-self: center;
  justify-self: center;
  font-weight: 400;
  font-size: 44px;
  color: black;
}

.card-body-container {
  display: flex;
  flex-direction: column;
}

.experience-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 250px 300px;
  gap: 60px;
}

.experience-item {
  width: 500px;
  height: auto;
}

.experience-item:hover {
  opacity: 0.5;
}

.experience-title {
  position: absolute;
  color: black;
  font-size: 25px;
  font-weight: bold;
  width: 500px;
  z-index: 9999;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  text-align: center;
}

.experience-child-container {
  position: relative;
}

.company-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 50px;
}

.company-item {
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
  color: black;
}

.feedbacks-container {
  display: grid;
  gap: 50px;
  grid-template-columns: 500px 500px;
}

.feedback-portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.groupe-modal {
  min-width: 1219px;
  height: auto;
}

.groupe-modal-header-text-details {
  width: 848px;
}

.groupe-first-block {
  min-width: 573px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.groupe-container {
  display: flex;
  gap: 60px;
}

.input-file-container {
  border-radius: 6px;
  text-align: center;
  border-style: dashed;
  border-color: #dee2e6;
  min-width: 510px;
}

.input-file-container:hover {
  background-color: #5ce8ab;
}

.file-label {
  cursor: pointer;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.label-style {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
}

.file-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.custom-button {
  all: unset;
  color: #5e72e4;
  font-weight: '600';
}

.CSVImporter_FormatRawPreview__scroll {
  display: none;
}

.CSVImporter_FileStep__header {
  display: none !important;
}

.network-list .card-filter .card-body {
  padding: 5px !important
}

.error-input {
  border: 1.8px solid #fa5252 !important;
  border-radius: 4px !important;
}

@media screen and (max-width: 1500px) {
  .network-list .card-filter .card-body .custom-control-label {
    font-size: 13px !important
  }

  .network-list .card-filter .custom-control-label::before {
    top: 0.07rem !important
  }
}