.card-job-header {
  background-color: #e9ecef !important;
  height: 50px;
  display: flex;
  align-items: center;
}

.job-container {
  display: flex;
  justify-content: space-between;
}

.job-description-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.job-locations {
  display: flex;
  gap: 1rem;
}

.job-location-item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.job-location-item p {
  margin-bottom: 0px !important;
}

.company-details {
  display: flex;
  gap: 0.5rem;
  word-wrap: break-word;
}

.required-skills {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.6rem;
  height: 3.8rem;
}

.required-skills-text {
  color: black;
  font-weight: 500;
  margin-bottom: 0px !important;
  font-size: 12px;
}

.skill-text {
  color: black;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill {
  display: flex;
  background-color: #f7f7f7;
  border-radius: 4px;
}

p {
  margin-bottom: 0px !important;
}

.button-container {
  display: flex;
  gap: 15px;
}

.view-details {
  color: white;
  border: 0;
  font-size: 12px;
  padding: 10px;
  border-radius: 4px;
}

.view-details-secondary {
  background-color: #505050;
  color: white;
  border: 0;
  font-size: 12px;
  padding: 10px;
  border-radius: 4px;
}

.view-details-secondary.disabled {
  background-color: #505050;
  color: white;
  border: 0;
  font-size: 12px;
  padding: 10px;
  border-radius: 4px;
}

.share {
  color: white;
  border: 0;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
}

.job-parent-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.daily-rate {
  font-size: 12px;
  color: black;
}

.daily-price {
  font-size: 30px;
  color: black;
}

.number-of-applicants {
  color: black;
}

.number-of-views {
  color: black;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 50px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked+.switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 60px;
}

.switch-container {
  position: relative;
}

.switch-text {
  font-size: 0.7rem;
}


/* switch input css */

.jobs-container .custom-switch .custom-control-label::before {
  width: 3.5rem;
  height: 22px;
  pointer-events: all;
  border: solid 1px #63b1bd;
}

.jobs-container .custom-switch .custom-control-label:after {
  left: -3.4rem;
  top: 7px;
  height: 15px;
  width: 15px;
  box-shadow: -1px 2px 3px 0 rgb(34 41 47 / 20%);
  background-color: #63b1bd;
}

.jobs-container .custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #63b1bd;
}

.jobs-container .custom-switch.switch-on .custom-control-label::after {
  left: -1.5rem;
  background-color: #63b1bd;
}

.jobs-container .custom-control-input:checked~.custom-control-label::before {
  background-color: #fff;
}

.jobs-container .custom-control-input:checked~.custom-control-label span {
  color: #63b1bd !important;
}

.jobs-container .custom-switch .custom-control-label span.yes-label {
  right: 48px;
  font-size: 13px;
  line-height: 25px;
  color: #fff;
  top: 3.5px;
}

.jobs-container .custom-switch .custom-control-label span.no-label {
  right: 30px;
  top: 3.5px;
  font-size: 13px;
  line-height: 25px;
  color: #63b1bd;
}

.status {
  font-size: 0.85rem;
}

@media screen and (min-width: 1445px) {
  .jobs-container .custom-switch .custom-control-label span {
    top: 1.5px !important;
  }

  .jobs-container .show-unread-filter .custom-switch .custom-control-label span {
    top: 1px !important;
  }
}

.jobs-container .jobboard-filter-badge {
  background-color: #f6f6f6;
  border-radius: 4px;
  height: max-content;
}

.jobs-container .css-1jqq78o-placeholder {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.jobs-container .css-13cymwt-control {
  height: 100%;
  max-height: 100%;
}

.jobs-container .css-13cymwt-control .css-3w2yfm-ValueContainer {
  height: 100%;
  overflow-y: auto;
  max-height: 100%;
}

.tooltip .tooltip-inner {
  background-color: #fff;
  border: solid 1px #e9ecef;
  display: flex;
  align-items: center;
}

.tooltip .arrow::before {
  border-top-color: #e9ecef !important;
  border-bottom-color: #e9ecef !important;
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.nav-tabs .nav-link {
  border: none;
  margin-bottom: 0;
}

.nav-tabs .nav-link:hover {
  border: none;
  color: #fa5454 !important;
}

.nav-tabs .active {
  border: none;
  color: #fa5454 !important;
  font-weight: 600 !important;
}

.filter-jobboard .filter-jobboard-item .css-b62m3t-container {
  width: calc(100% - 255px - 1rem) !important;
}

@media screen and (max-width: 979px) {
  .filter-jobboard-item {
    flex-direction: column !important;
  }

  .filter-jobboard .filter-jobboard-item .css-b62m3t-container {
    width: 100% !important;
  }
}