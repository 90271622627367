.modal-content {
  height: max-content;
  max-height: 100%;
  overflow-y: scroll;
}

.title-welcome-onboarding {
  font-size: 40px;
  margin-bottom: 1rem;
}

.sub-title-welcome-onboarding {
  font-size: 20px;
  margin-bottom: 1.2rem;
}

.stepper-wrapper {
  font-family: Arial;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #000000;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.stepper-one-flex {
  align-items: center;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-wrap {
  white-space: wrap;
}

.error-container {
  position: absolute;
  bottom: -5px;
}

.overriden-height {
  height: 38px;
}

.column-gap-stepper {
  row-gap: 1rem;
}

.custom-control {
  z-index: 0 !important;
}


.fallback label {
  border-color: hsl(0, 0%, 80%);
  width: 100%;
  max-width: unset;
  height: 100px;
  /* margin-bottom: 10px; */
}

.fallback label span {
  color: hsl(0, 0%, 80%);
}

.fallback label svg path {
  fill: hsl(0, 0%, 80%) !important;
}

.btn-next-link {
  color: #fa5252 !important;
  font-size: 13px !important;
}

.btn-next-link:hover::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid;
}

.btn-close {
  color: #4f4f4f;
  font-size: 13px !important;
}

.onboarding-modal .modal-header,
.first-step-modal .modal-header {
  background-color: #FA5252;
  padding: 4px 0 !important;
}

.onboarding-modal .modal-footer {
  background-color: #80808014;
  border-top: 1px solid #8080803d;
  padding: 0.5rem !important
}

.onboarding-modal .close span:not(.sr-only),
.first-step-modal .close span:not(.sr-only) {
  font-size: 25px;
}

.onboarding-modal .css-1p3m7a8-multiValue {
  background: linear-gradient(90deg,
      #63b1bd 0%,
      rgba(99, 177, 189, 0.6) 100%) !important;
  color: white !important;
  text-transform: capitalize !important;
}

.onboarding-modal .css-9jq23d {
  color: white !important;
}

.onboarding-modal .css-13cymwt-control {
  height: auto
}

.onboarding-modal .css-b62m3t-container {
  min-width: 200px !important
}

.onboarding-modal .css-wsp0cs-MultiValueGeneric {
  color: white !important;
}

.onboarding-modal .third-step-card {
  border: 1px solid #8898AA;
  border-radius: 3px;
}


.onboarding-modal .third-step-header-card {
  padding: 30px;
  color: #8898AA;
}

.onboarding-modal .third-step-content-card {
  padding: 5rem 3rem;
  color: #32325D;
}

.onboarding-modal .third-step-card:hover {
  border-color: #fa5252 !important;
  background-color: #fa5252 !important;
  cursor: pointer !important;
  color: white !important;
}

.onboarding-modal .third-step-card:hover .third-step-header-card,
.onboarding-modal .third-step-card:hover .third-step-content-card {
  color: white !important;
}

.onboarding-modal .third-step-card-active {
  border-color: #fa5252 !important;
  background-color: #fa5252 !important;
  cursor: pointer !important;
  color: white !important;
}

.onboarding-modal .third-step-card-active .third-step-header-card,
.onboarding-modal .third-step-card-active .third-step-content-card {
  color: white !important;
}

.onboarding-modal .error-input .css-13cymwt-control {
  border: 0;
}

.onboarding-modal .btn:focus,
.onboarding-modal .btn.focus,
.onboarding-modal .btn:not(:disabled):not(.disabled):active:focus,
.onboarding-modal .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: unset !important
}

@media screen and (max-width: 991px) {
  .onboarding-modal .margin-md-checkbox {
    margin-top: 3rem;
  }
}

.tooltip-inner {
  color: black !important;
  font-size: 12px
}

.cursor-pointer {
  cursor: pointer;
}

.first-step-onboarding .form-check-input {
  cursor: pointer;
  margin: unset !important;
  position: relative !important
}

.last-screen-icon {
  font-size: 10rem;
}

.input-group {
  width: 100%;
  flex-wrap: nowrap;
}

/* .input-group>div>span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
} */

@media screen and (max-width: 768px) {
  .title-welcome-onboarding {
    font-size: 28px;
  }

  .container.gap-2 {
    grid-gap: 0;
    gap: 0;
    flex-direction: column;
    align-items: baseline !important;
  }

  .sub-title-welcome-onboarding {
    font-size: 20px;
  }

  .onboarding-modal .my-2,
  .onboarding-modal .my-2 input {
    width: 100% !important;
  }

  .modal-footer {
    flex-wrap: unset;
  }

  .last-screen-icon {
    font-size: 4rem;
  }
}

.second-stepper-screen-form .css-b62m3t-container {
  display: unset !important;
}

.custom-scrollbar__control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.onboarding-modal .css-13cymwt-control {
  height: 100% !important;
}