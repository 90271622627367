.flags {
    .dropdown-menu {
        padding: 10px;
    }

    .dropdown-item {
        &:hover {
            background-color: #e8ebed;
        }

        &.active {
            color: #fff;
            text-decoration: none;
            background-color: #F35052;
        }
    }
}