.user-experiences-block {

    div.rounded-lg {
        width: 15rem;
        height: 15rem;
        position: relative;

        img {
            object-fit: fill;
        }
    }

    @media screen and (max-width: 1440px) {
        div.rounded-lg {
            width: 13rem;
            height: 13rem;
        }
    }
}